
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import JwtService from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    id: { required: true },
  },

  name: "apps-create-purchase-rquisition-details",
  components: {},

  setup(props) {
    const loading = ref<boolean>(false);
    const isEditModeOn = ref<boolean>(false);
    const dialogSelectProductTableVisible = ref(false);
    const enquiryOffersList = ref<Array<any>>([]);
    const requiredQty = ref('');
    const dialogCreateOfferTableVisible = ref(false);
    const offerFormRef = ref<null | HTMLFormElement>(null);
    const incoterms_data = ref([]);
    const expeceted_delivery_data = ref([]);
    const payment_term_data = ref([]);
    const make_company_data = ref([]);
    const plant_data = ref([]);
    const brand_list = ref([]);
    const productChargeData: any = ref([]);
    const category = ref("");
    const productDescription = ref([]);
    const router = useRouter();
    const store = useStore();

    const offerData: any = ref({
      sales_inquiry_details_id: "",
      offer_qty: "",
      incoterm_id: "",
      delivery_term_id: "",
      payment_terms_id: "",
      offer_remark: "",
      offer_rate: "",
      offer_amount: "",
      offer_total_amount: ref<any>(""),
      total_amount: ref<any>(""),
      offer_round_off_amount: ref<any>(""),
      offer_grand_total_amount: ref<any>(""),
      uom_name: "MT",
      is_igst_applicable: 0,
      igst_amount: ref<any>(""),
      cgst_amount: ref<any>(""),
      sgst_amount: ref<any>(""),
      required_qty: "",
      make_company_id: "",
      plant_id: "",
      brand_id: "",
      product_name: "",
    });

    const offerRules = ref({
      incoterm_id: [
        {
          required: true,
          message: "Incoterm is required",
          trigger: "change",
        },
      ],
      delivery_term_id: [
        {
          required: true,
          message: "Deliveryterm is required",
          trigger: "change",
        },
      ],
      payment_terms_id: [
        {
          required: true,
          message: "Deliveryterm is required",
          trigger: "change",
        },
      ],
      // make_company_id: [
      //   {
      //     required: true,
      //     message: "Manufacturer is required",
      //     trigger: "change",
      //   },
      // ],
      // plant_id: [
      //   {
      //     required: true,
      //     message: "Plant is required",
      //     trigger: "change",
      //   },
      // ],
      offer_qty: [
        {
          required: true,
          message: "Offer quantity is required",
          trigger: "change",
        },
      ],
      offer_rate: [
        {
          required: true,
          message: "Offer rate is required",
          trigger: "change",
        },
      ],
    });

    const resetForm = () => {
      offerData.value = {
        sales_inquiry_details_id: "",
        offer_qty: "",
        incoterm_id: "",
        delivery_term_id: "",
        offer_remark: "",
        offer_rate: "",
        offer_amount: "",
        offer_total_amount: ref<any>(""),
        total_amount: ref<any>(""),
        offer_round_off_amount: ref<any>(""),
        offer_grand_total_amount: ref<any>(""),
        uom_name: "MT",
        is_igst_applicable: 0,
        igst_amount: ref<any>(""),
        cgst_amount: ref<any>(""),
        sgst_amount: ref<any>(""),
        required_qty: "",
        make_company_id: "",
        tax: "",
      };
      productChargeData.value = [];
      dialogCreateOfferTableVisible.value = false;
    };

    const createNewOffer = () => {
      let offerQty = enquiryOffersList?.value[0]?.required_quantity ? 
                     enquiryOffersList?.value[0]?.required_quantity : 
                     requiredQty.value;
      offerData.value.required_qty = offerQty;
      offerData.value.offer_qty = parseFloat(offerQty);
      getProductChargeList();
      dialogCreateOfferTableVisible.value = true;
    };

    async function getIncoterms() {
      await store
        .dispatch(Actions.CUST_PRODUCT_INCOTERM)
        .then(({ data }) => {
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getDeliveryTerms() {
      await store
        .dispatch(Actions.CUST_PRODUCT_DELIVERY_TERM)
        .then(({ data }) => {
          expeceted_delivery_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPaymentTerms() {
      await store
        .dispatch(Actions.CUST_GET_PAYMENT_TERM)
        .then(({ data }) => {
          debugger;
          payment_term_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getPlants = async () => {
      let values = {
        company_branch_id: 0,
        company_id: offerData.value.make_company_id,
        page: 1,
        records_per_page: 50,
      };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_PLANT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          plant_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const getBrands = async () => {
      let values = {
        company_branch_id: 0,
        company_id: offerData.value.make_company_id,
        page: 1,
        records_per_page: 50,
      };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_BRAND_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          brand_list.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    async function getProductChargeList() {
      try {
        await store
          .dispatch(ActionsFi.CUST_GET_QUOTATION_PRODUCT_CHARGE_LIST)
          .then(({ data }) => {
            if (data?.length) {
              let newChargeData = data.map((item, index) => {
                (item.rate = ""),
                  (item.totalAount = ""),
                  (item.igstTax = ""),
                  (item.igstAmount = ""),
                  (item.total = "");
                return item;
              });
              productChargeData.value = newChargeData;
            }
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    }

    const calculateTableData = (row) => {
      console.log("rowName", row);
      let totalAmount = 0;
      let chargedTax = 0;
      let total = 0;
      if (!/^\d*\.?\d*$/.test(row.rate)) {
        row.rate = row.rate.slice(0, -1);
        return;
      }
      let offerQty = !isNaN(parseFloat(offerData.value.offer_qty))
        ? parseFloat(offerData.value.offer_qty)
        : 0;
      let rate = !isNaN(parseFloat(row.rate)) ? parseFloat(row.rate) : 0;

      totalAmount = offerQty * rate;
      row.totalAmount = totalAmount.toFixed(2).toString();
      row.tax = "18%";
      chargedTax = (totalAmount * 18) / 100;
      row.igstAmount = chargedTax.toFixed(2).toString();
      total = totalAmount + chargedTax;
      row.total = total.toFixed(2).toString();
      calculateTableDataTotal();
    };

    const calculateTableDataTotal = () => {
      let grandTotal = 0;
      let rowTotal = 0;
      let roundUp = 0;
      for (let row of productChargeData.value as any) {
        rowTotal = parseFloat(row.total) ? parseFloat(row.total) : 0;
        grandTotal = rowTotal + grandTotal;
      }
      offerData.value.total_amount = (
        grandTotal + parseFloat(offerData.value.offer_total_amount)
      ).toFixed(2);

      roundUp = Math.round(offerData.value.total_amount);
      offerData.value.offer_grand_total_amount = roundUp.toFixed(2);

      offerData.value.offer_round_off_amount = (
        parseFloat(offerData.value.total_amount) - roundUp
      ).toFixed(2);
    };

    const calculateFirstRow = (field) => {
      let appliedTax = 0;

      if (!/^\d*\.?\d*$/.test(offerData.value[field])) {
        offerData.value[field] = offerData.value[field].slice(0, -1);
        return;
      } else {
        let offerAmount =
          parseFloat(offerData.value.offer_qty) *
          parseFloat(offerData.value.offer_rate);
        offerData.value.offer_amount = !isNaN(offerAmount)
          ? offerAmount.toFixed(2)
          : 0;
        offerData.value.tax = "18%";
        appliedTax = (18 * parseFloat(offerData.value.offer_amount)) / 100;
        offerData.value.igst_amount = appliedTax.toFixed(2);
        offerData.value.offer_total_amount = (
          parseFloat(appliedTax.toString()) +
          parseFloat(offerData.value.offer_amount)
        ).toFixed(2);

        calculateTableDataTotal();
      }
    };

    const submitOffer = async () => {
      loading.value = true;

      if (!offerFormRef.value) {
        loading.value = false;
        return;
      }

      offerFormRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(offerData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const setCompanyData = async (data) => {
      let user = JSON.parse(JwtService.getToken());

      let productOtherCharges = productChargeData.value.map((item) => {
        return {
          charge_id: item.charge_id,
          charge_rate: item.rate,
          charge_taxable_amount: item.totalAmount,
          charge_tax: item.igstAmount,
          charge_total_amount: item.total,
        };
      });

      let requestBody = {
        se_detail_id: props.id,
        mfg_company_id: data.make_company_id,
        mfg_plant_id: data.plant_id,
        brand_id: data.brand_id,
        required_quantity: data.required_qty,
        offered_quantity: data.offer_qty,
        product_rate_offer: data.offer_rate,
        product_taxable_amount: data.offer_amount,
        product_tax: data.igst_amount,
        product_total: data.offer_total_amount,
        product_other_charges_offer: productOtherCharges,
        total_amount: data.total_amount,
        round_up_amount: data.offer_round_off_amount,
        grand_total_amount: data.offer_grand_total_amount,
        payment_term_id: data.payment_terms_id,
        incoterm_id: data.incoterm_id,
        delivery_term_id: data.delivery_term_id,
        offer_remark: data.offer_remark,
        user_id: user.user_id,
      };
      console.log("finalBody", requestBody);
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_SALES_OFFER, requestBody)
          .then(({ data }) => {
            loading.value = false;
            if (data?.se_offer_id) {
              setTimeout(() => {
                Swal.fire({
                  text: "Offer create successfully!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  dialogCreateOfferTableVisible.value = false;
                  getListOfferOfEnquiry();
                });
              }, 2000);
            } else {
              Swal.fire({
                text: "Sorry, looks like there are some errors detected, please try again.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    async function getListOfferOfEnquiry() {
      let params = { se_detail_id: props.id };
      await store
        .dispatch(ActionsFi.CUST_GET_LIST_OFFERS_BY_ENQUIRY, params)
        .then(({ data, manufacturers_list, category_name, product_desc, required_quantity }) => {
          debugger;
          enquiryOffersList.value = data;
          make_company_data.value = manufacturers_list;
          category.value = category_name;
          productDescription.value = product_desc;
          requiredQty.value = required_quantity
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Sales offer", []);
      await getListOfferOfEnquiry();
      await getIncoterms();
      await getDeliveryTerms();
      await getPaymentTerms();
      await getProductChargeList();
    });

    return {
      isEditModeOn,
      createNewOffer,
      dialogSelectProductTableVisible,
      loading,
      enquiryOffersList,
      dialogCreateOfferTableVisible,
      offerData,
      offerFormRef,
      offerRules,
      incoterms_data,
      expeceted_delivery_data,
      payment_term_data,
      getPlants,
      plant_data,
      getBrands,
      brand_list,
      submitOffer,
      productChargeData,
      calculateTableData,
      calculateFirstRow,
      make_company_data,
      resetForm,
      category,
      productDescription,
      requiredQty
    };
  },
});
